<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('aqvvesselloadfactor.title')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md2>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-select
								attach="aqversionloadfactorid"
								:label="this.$t('aqvvesselloadfactor.versionloadfactor')"
								:placeholder="this.$t('aqvvesselloadfactor.phversionloadfactor')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqversionloadfactor"
								:modelFormMapping="{ aqversionloadfactorid: 'versionloadfactor' }"
								:itemsToSelect="versionloadfactorItemsToSelect"
								itemValue="aqversionloadfactorid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-select
								attach="aqvesseltypesid"
								:label="this.$t('aqvvesselloadfactor.vesseltype')"
								:placeholder="this.$t('aqvvesselloadfactor.phvesseltype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqvesseltypes"
								:modelFormMapping="{ aqvesseltypesid: 'vesseltype' }"
								:itemsToSelect="vesseltypeItemsToSelect"
								itemValue="aqvesseltypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.auxanc')"
								v-model="model.auxanc"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.auxcrui')"
								v-model="model.auxcrui"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.auxhot')"
								v-model="model.auxhot"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.auxman')"
								v-model="model.auxman"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.mainanc')"
								v-model="model.mainanc"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.maincrui')"
								v-model="model.maincrui"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.mainhot')"
								v-model="model.mainhot"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md3>
							<pui-text-field
								:label="$t('aqvvesselloadfactor.mainman')"
								v-model="model.mainman"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md12>
							<pui-text-area
								v-model="model.description"
								:label="$t('aqvvesselloadfactor.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('aqvvesselloadfactor.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'aqvvesselloadfactorform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'aqvvesselloadfactor',
			model: 'aqvvesselloadfactor'
		};
	},
	computed: {
		vesseltypeItemsToSelect() {
			return [{ aqvesseltypesid: this.model.vesseltypesid }];
		},

		versionloadfactorItemsToSelect() {
			return [{ aqversionloadfactorid: this.model.aqversionloadfactorid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {}
};
</script>
